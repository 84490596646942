// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-3-d-index-js": () => import("./../../../src/pages/3D/index.js" /* webpackChunkName: "component---src-pages-3-d-index-js" */),
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-canvas-index-js": () => import("./../../../src/pages/canvas/index.js" /* webpackChunkName: "component---src-pages-canvas-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-type-index-js": () => import("./../../../src/pages/type/index.js" /* webpackChunkName: "component---src-pages-type-index-js" */)
}

